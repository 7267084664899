import React from "react";
import { useTranslation } from "react-i18next";
import { ApiHook } from "../../hooks/apiHook";
import { NavLink, useLocation } from "react-router-dom";

const MailSidebarContent = ({ setShowCompose, setMailContent, mailContent, count }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const sendMailContent = ApiHook.CallSendMailData();
  
  const handleCompose = () => {
    setShowCompose(true);
    sendMailContent.mutate(
      { sendMailContent },
      {
        onSuccess: (res) => {
          if (res.data.status) {
            setMailContent(res.data.data);
          }
        },
      }
    );
  };

  const navLinks = [
    { path: "/mailbox", icon: "fa-regular fa-envelope-open", label: t("inbox") },
    { path: "/mailbox/send", icon: "fa-regular fa-paper-plane", label: t("send") },
    { path: "/mailbox/admin-inbox", icon: "fa-solid fa-pen", label: t("admin_inbox") },
  ];

  return (
    <>
      <div className="menu-bar">
        <ul className="menu-items">
          <li className="compose mb-3">
            <button
              data-bs-toggle="offcanvas"
              data-bs-target="#composemail"
              aria-controls="composemail"
              className="btn btn-primary btn-block"
              onClick={handleCompose}
            >
              {t("compose")}
            </button>
          </li>
          {navLinks.map((link, index) => (
            <li key={index} className={location.pathname === link.path ? "active" : ""}>
              <NavLink to={link.path}>
                <i className={link.icon}></i> {link.label}
              </NavLink>
              {link.path === "/mailbox" && <span className="badge badge-pill bg-success">{count.inbox}</span>}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default MailSidebarContent;

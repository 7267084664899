import React from "react";
import { Offcanvas, Button } from "react-bootstrap";

const StripeValidation = ({
  show,
  handleClose,
  data,
}) => {
  const popupStyle = {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
  };

  const popupContentStyle = {
    background: 'white',
    padding: '80px',
    borderRadius: '8px',
    textAlign: 'center',
  };
  const handleRedirect = () => {
    window.location.href = data;
  };
  return (
    <div style={popupStyle}>
      <div style={popupContentStyle}>
        <h2 style={{color:"rgb(126, 11, 11)"}}>Stripe Account Not Activated !!!!!</h2>
        <p>To Activate Account please continue</p>
        <Button variant="secondary" onClick={handleClose} style={{marginRight:"10px"}}>
          Close
        </Button>
        <Button variant="primary" onClick={handleRedirect}>
          Continue
        </Button>
      </div>
    </div>
  );
};

export default StripeValidation;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiHook } from "../../hooks/apiHook";
import moment from "moment";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const SendMailContent = ({ mailId, mailCheck, setMailCheck, type }) => {
  const { t } = useTranslation();
  const [replyBtn, setReplyBtn] = useState(false);
  const [replyMessage, setReplyMessage] = useState("");
  const [expandedItems, setExpandedItems] = useState([]);
  const [value, setValue] = useState("");
  const [data, setData] = useState([]);
  //----------------------------------------- API -------------------------------------------
  const singleMailDetails = ApiHook.CallSingleMailDetails(
    mailId,
    mailCheck,
    setMailCheck
  );
  const deleteMutation = ApiHook.CallDeleteMail();

  const filterHTML = (input) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = input;
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  const toggleAccordionItem = (index) => {
    if (expandedItems.includes(index)) {
      setExpandedItems(expandedItems.filter((item) => item !== index));
    } else {
      setExpandedItems([...expandedItems, index]);
    }
  };

 
  const deleteMail = () => {
     //   const lastIndex = data.data?.length - 1;
  //  const id = data.data[lastIndex].id
    deleteMutation.mutate(mailId, {
      onSuccess: (res) => {
        if (res.status) {
          toast.success(res.data?.data);
         
        }
      },
    });
  };



  useEffect(() => {
    const lastIndex = singleMailDetails.data?.length - 1;
    if (lastIndex >= 0) {
      setExpandedItems([...Array(lastIndex).keys()]); // Collapse all items except the last one
    }
    if (singleMailDetails.isSuccess) {
      setData(singleMailDetails);
    }
  }, [singleMailDetails.data]);
  useEffect(() => {
    setData({});
  }, [type]);

  return (
    <>
      <div className="row">
        <div className="col-md-12 mb-4 mt-4">
          <div className="btn-toolbar">
            {/* <div className="btn-group"> */}
              {/* <button
                type="button"
                className="btn btn-sm btn-outline-secondary"
              >
                <i className="fa-solid fa-reply"></i> {t("reply")}
              </button>
              <button
                type="button"
                className="btn btn-sm btn-outline-secondary"
              >
                <i className="fa-solid fa-reply-all"></i> {t("replyAll")}
              </button>
              <button
                type="button"
                className="btn btn-sm btn-outline-secondary"
              >
                <i className="fa-solid fa-share"></i> {t("forward")}
              </button>
            </div> */}
            <div className="btn-group">
              <button
                type="button"
                className="btn btn-sm btn-outline-secondary"
                onClick={() => deleteMail()}
              >
                <i className="fa-regular fa-trash-can"></i> {t("delete")}
              </button>
            </div>
          </div>
        </div>
      </div>
      {data?.length == 0 && (
        <div className="nodata-table-view">
          <div className="nodata-table-view-box" >
            <div className="nodata-table-view-box-img">
              <img src="/images/no-data-image1.jpg" alt=""/>
            </div>
            <div className="nodata-table-view-box-txt">
              {t("sorry_no_data_found")}
            </div>
          </div>
        </div>
      )}
      {data.data && (
        <div className="message-body">
          <div className="accordion" id="accordionExample">
            {data.data?.map((item, index) => (
              <div className="accordion-item" key={index}>
                <h2 className="accordion-header" id={`heading${index}`}>
                  <button
                    className="accordion-button"
                    type="button"
                    aria-expanded={expandedItems.includes(index)}
                    onClick={() => toggleAccordionItem(index)}
                  >
                    <div className="sender-details">
                      <img
                        className="img-sm rounded-circle mr-3"
                        src="http://www.urbanui.com/dashflat/template/images/faces/face11.jpg"
                        alt=""
                      />
                      <div className="details">
                        <p className="msg-subject">
                          {t("subject")} : {item.subject}
                        </p>
                        <p className="sender-email">
                          To: {item.toUsername} (
                          <a className="maildtl" href="#">
                            {item.toUserMail}
                          </a>
                          ) <br />
                          <span>
                            {t("date")}:{" "}
                            {moment(item.createdAt).format(
                              "ddd, MMM D, YYYY [at] h:mm A"
                            )}
                          </span>
                          <i className="mdi mdi-account-multiple-plus"></i>
                        </p>
                      </div>
                    </div>
                  </button>
                </h2>
                <div
                  id={`collapse${index}`}
                  className={`accordion-collapse ${
                    expandedItems.includes(index) ? "collapse" : ""
                  } `}
                  aria-labelledby={`heading${index}`}
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <div className="message-content">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: filterHTML(item.message),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default SendMailContent;

// const BASE_URL = "http://192.168.6.23:5000/";
// const BASE_URL = "https://api.wappss.com/";
// const BASE_URL = "https://api.infinitemlmsoftware.com/";

const DEFAULT_KEY = '387245a5a919ea2071cc406b10b89d4685e5cc8e'
const PAYPAL_CLIENT_ID = "Ad077-Gx8stGcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75"
// const BASE_URL = "http://192.168.23.47:5007/";

// const BASE_URL = "http://162.19.146.135:1358/";

const BASE_URL = "http://50.62.182.35:5000/";
export { BASE_URL, DEFAULT_KEY, PAYPAL_CLIENT_ID } 
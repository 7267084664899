import React, { useState, useEffect } from "react";
import MailViewContent from "./MailViewContent";
import MailSidebarContent from "./MailSidebarContent";
import SendMailContent from "./SendMailContent";
import MailListContainerContent from "./MailListContainerContent";
import MailCompose from "./MailCompose";
import { ApiHook } from "../../hooks/apiHook";
import { useLocation } from "react-router-dom";

const MailboxComponent = () => {
  const [showCompose, setShowCompose] = useState(false);
  const [singleMailId, setSingleMailId] = useState("");
  const [mailContent, setMailContent] = useState(null);
  const [mailCheck, setMailCheck] = useState(false);
  const [inboxData, setInboxData] = useState(0);
  const [data, setData] = useState();

  const location = useLocation();
  const pathname = location.pathname;
  const trimmedPathname = pathname.split("/").pop();
  console.log(trimmedPathname, "location");

  const inboxes = ApiHook.CallInboxes();
  const send = ApiHook.CallSend();
  const admin = ApiHook.CallAdmin();

  const functionAPI = async (trimmedPathname) => {
    // Call the admin inbox mutation when the component mounts
    if (trimmedPathname === "admin-inbox") {
      admin.mutate(
        { page: 1, limit: 10 },
        {
          onSuccess: (res) => {
            console.log(res,"res");
            
              setData(res.mail);
            
          },
        }
      );
    } else if (trimmedPathname === "send") {
      // Call the send mutation when the component mounts
      send.mutate(
        { page: 1, limit: 10 },
        {
          onSuccess: (res) => {
            if (res.data.status) {
              setData(res.data.data);
            }
          },
        }
      );
    } else if (trimmedPathname === "mailbox") {
      // Call the inbox mutation when the component mounts
      inboxes.mutate(
        { page: 1, limit: 10 },
        {
          onSuccess: (res) => {
            if (res.status) {
              setInboxData(res.inboxUnreadCount);
              setData(res.inboxData.data);
            }
          },
        }
      );
    }
  };

  useEffect(() => {
    setData([]);
    functionAPI(trimmedPathname);
  }, [trimmedPathname]);

  return (
    <>
      <div className="content-wrapper">
        <div className="email-wrapper wrapper">
          <div className="row align-items-stretch">
            <div className="mail-sidebar d-none d-lg-block col-md-2 pt-3 bg-white">
              {/* Sidebar content */}
              <MailSidebarContent
                setShowCompose={setShowCompose}
                count={inboxData}
                setMailContent={setMailContent}
                mailContent={mailContent}
              />
            </div>
            <div className="mail-list-container col-md-3 pt-4 pb-4 border-right bg-white">
              {/* Mail list container content */}
              <MailListContainerContent
                inboxes={data}
                setSingleMailId={setSingleMailId}
                setMailCheck={setMailCheck}
                type={trimmedPathname}
              />
            </div>
            {trimmedPathname !== "send" ? (
              <div className="mail-view col-md-9 col-lg-7 bg-white">
                <MailViewContent
                  mailId={singleMailId}
                  mailCheck={mailCheck}
                  setMailCheck={setMailCheck}
                  type={trimmedPathname}
                />
              </div>
            ) : (
              <div className="mail-view col-md-9 col-lg-7 bg-white">
                <SendMailContent
                  mailId={singleMailId}
                  mailCheck={mailCheck}
                  setMailCheck={setMailCheck}
                  type={trimmedPathname}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Compose Mail */}
      <MailCompose
        showCompose={showCompose}
        setShowCompose={setShowCompose}
        mailContent={mailContent}
      />
    </>
  );
};

export default MailboxComponent;

import React, { useRef, useState } from "react";
import LabelledButton from "../buttons/LabelledButton";
import SubmitButton from "../buttons/SubmitButton";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import { exportToCSV, exportToExcel } from "../../../utils/tableExports";
import TableContent from "./TableContent";

const PayoutTableFilter = ({
  setActiveTab,
  activeTab,
  headers,
  data,
  type,
}) => {
  const [selectedValue, setSelectedValue] = useState(activeTab);
  const { t } = useTranslation();
  const tableRef = useRef();

  const handleTabChange = (e) => {
    const selectedValue = document.getElementById("category").value;
    if (e.target.id === "searchButton") {
      setActiveTab(selectedValue);
    } else if (e.target.id === "resetButton") {
      setActiveTab("paid");
      setSelectedValue("paid");
    }
  };
  

  const handleSelectChange = (e) => {
    setSelectedValue(e.target.value);
  };

  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
  });

  return (
    <>
      <div className="filter_Section">
        <div className="row justify-content-between">
          <div className="col-md-4">
            <div className="right_search_div d-flex gap-1">
              <select
                name="category"
                className="form-control"
                id="category"
                value={selectedValue}
                onChange={handleSelectChange}
              >
                <option value="paid">{t("paid")}</option>
                <option value="requested">{t("requested")}</option>
                <option value="approved">{t("approved")}</option>
                <option value="rejected">{t("rejected")}</option>
              </select>
              <SubmitButton
                isSubmitting=""
                text="Search"
                className="btn btn-primary"
                click={handleTabChange}
                id="searchButton"
              />
              <SubmitButton
                isSubmitting=""
                text="Reset"
                className="btn btn-secondary"
                click={handleTabChange}
                id="resetButton"
              />
            </div>
          </div>
          <div className="col-md-4 text-end mob_filter_right">
            <LabelledButton
              className="fa fa-file-excel"
              text=" Excel"
              click={() => exportToExcel(data, headers, type)}
            />
            <LabelledButton
              className="fa fa-file-text"
              text=" CSV"
              click={() => exportToCSV(data, headers, type)}
            />
            <LabelledButton
              className="fa fa-print"
              text=" Print"
              click={handlePrint}
            />
          </div>
          <div style={{ display: "none", padding: "10px" }}>
            <TableContent
              tableRef={tableRef}
              data={data}
              headers={headers}
              type={"payout"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PayoutTableFilter;

import React from "react";
import { toast } from "react-toastify";

const ContactUs = ({ companyDetails }) => {
  const handleSubmit = () => {
    toast.success("Message sent successfully");
  };

  return (
    <div className="contact__three page section-padding" id="contact">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6">
            <div className="contact__two-info">
              <h2 className="mb-60 lg-mb-30">
                <span>Get in Touch</span>
              </h2>
              <div className="contact__two-info-item">
                <h6>
                  Office Address <span>:</span>
                </h6>
                {companyDetails?.address && (
                  <strong>{companyDetails?.address}</strong>
                )}
              </div>
              <div className="contact__two-info-item">
                <h6>
                  Email Address <span>:</span>
                </h6>
                <span>
                  {companyDetails?.email && (
                    <strong>{companyDetails?.email}</strong>
                  )}
                </span>
              </div>
              <div className="contact__two-info-item">
                <h6>
                  Phone Number <span>:</span>
                </h6>
                <span>
                  {companyDetails?.phone && (
                    <strong>{companyDetails?.phone}</strong>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="contact__three-form t-center">
              <div className="contact__three-form-title">
                <h2>Contact Us</h2>
              </div>
              <div className="row">
                <div className="col-md-6 mb-30">
                  <div className="contact__two-right-form-item contact-item">
                    <span className="fal fa-user"></span>
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      placeholder="Full Name"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6 md-mb-30">
                  <div className="contact__two-right-form-item contact-item">
                    <span className="far fa-envelope-open"></span>
                    <input
                      className="form-control"
                      type="email"
                      name="email"
                      placeholder="Email Address"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-30">
                  <div className="contact__two-right-form-item contact-item">
                    <span className="fal fa-user"></span>
                    <input
                      className="form-control"
                      type="text"
                      name="phone"
                      placeholder="Phone"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6 md-mb-30">
                  <div className="contact__two-right-form-item contact-item">
                    <span className="far fa-envelope-open"></span>
                    <input
                      className="form-control"
                      type="text"
                      name="address"
                      placeholder="Address"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-12 mb-30">
                  <div className="contact__two-right-form-item contact-item">
                    <span className="far fa-comments"></span>
                    <textarea
                      name="message"
                      className="form-control"
                      placeholder="Message"
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="contact__two-right-form-item">
                    <button className="btn-one" onClick={handleSubmit}>
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;

import React, { useState } from "react";
import { Button, Form, Offcanvas } from "react-bootstrap";
import { ApiHook } from "../../hooks/apiHook";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";

const MailCompose = ({ showCompose, setShowCompose, mailContent }) => {
  const { t } = useTranslation();
  const [value, setMessage] = useState("");

  const sendMailMutation = ApiHook.CallSendMail();

  const {
    register,
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = useForm();

  const [toValue, setToValue] = useState("");

  // ---------------------- watch the values of the Register Form --------------------------
  const formValues = watch();
  // ---------------------------------------------------------------------------------------

  const handleMailSend = async () => {
    const isValid = await trigger();
    console.log(errors);
    if (isValid) {
      sendMailMutation.mutate(formValues, {
        onSuccess: (res) => {
          if (res.status) {
            toast.success(t("mail_send"));
            setShowCompose(false);
            setValue("message", "");
            setValue("subject", "");
            setValue("type", null);
          }
        },
      });
    } else if (errors["type"] ||errors["message"]) {
      toast.error(t("type_required"));
    }
  };
  const handleReply = (value) => {
    setMessage(value);
    
      const doc = new DOMParser().parseFromString(value, "text/html");
      let data = doc.body.textContent || "";
      if (data !== ""){
      setValue("message", value);
    }else{
      toast.error(t("message_required"));
    }
    
  };
  const handleMailDiscard = async () => {
    setValue("message", "");
    setValue("subject", "");
    setValue("type", null);

    setShowCompose(false);
  };

  console.log(
    formValues,
    "===================formValues======================",
    toValue
  );
  return (
    <>
      <Offcanvas
        id="composemail"
        show={showCompose}
        onHide={() => setShowCompose(false)}
        placement="end"
        style={{ backgroundColor: "white" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>New Mail</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <main>
            <Form>
              <Form.Group className="mb-3" controlId="to">
                <Form.Label>Sub</Form.Label>
                <Form.Control
                  type="subject"
                  placeholder="Type subject"
                  className={`form-control ${
                    errors["subject"] ? "error-field" : ""
                  }`}
                  {...register("subject", {
                    required: {
                      value: true,
                      message: t("this_field_is_required"),
                    },
                  })}
                  onBlur={async () => await trigger("subject")}
                />
              </Form.Group>
              {mailContent?.length !== 0 ? (
                <Form.Group>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <Form.Label>Type </Form.Label>
                    <div>
                      <label>
                        <input
                          type="radio"
                          value="admin"
                          name="type"
                          {...register("type", {
                            required: {
                              value: true,
                              message: t("this_field_is_required"),
                            },
                          })}
                        />
                        Admin
                      </label>
                    </div>
                    <div>
                      <label>
                        <input
                          type="radio"
                          value="myTeam"
                          name="type"
                          {...register("type", {
                            required: {
                              value: true,
                              message: t("this_field_is_required"),
                            },
                          })}
                        />
                        Team
                      </label>
                    </div>
                    <div>
                      <label>
                        <input
                          type="radio"
                          value="individual"
                          name="type"
                          {...register("type", {
                            required: {
                              value: true,
                              message: t("this_field_is_required"),
                            },
                          })}
                        />
                        Individual
                      </label>
                    </div>
                  </div>
                </Form.Group>
              ) : (
                <Form.Group>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <Form.Label>Type </Form.Label>
                    <div>
                      <label>
                        <input
                          type="radio"
                          value="admin"
                          name="type"
                          {...register("type", {
                            required: {
                              value: true,
                              message: t("this_field_is_required"),
                            },
                          })}
                        />
                        Admin
                      </label>
                    </div>
                  </div>
                </Form.Group>
              )}

              {formValues.type === "individual" && (
                <Form.Group className="mb-3" controlId="to">
                  <Form.Label>To:</Form.Label>
                  <Form.Control
                    as="select"
                    value={toValue}
                    className={`form-control ${
                      errors["username"] ? "error-field" : ""
                    }`}
                    onChange={(e) => {
                      console.log(toValue, "toValue");
                      console.log(e.target.value, "e.target.value");
                      setToValue(e.target.value);
                      setValue("username", e.target.value);
                    }}
                  >
                    <option value="" disabled>
                      Select User
                    </option>
                    {mailContent.map((user) => (
                      <option key={user.id} value={user.value}>
                        {user.value}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              )}
            </Form>
            <div className="row">
              <div className="col ml-auto">
              <div>
              <ReactQuill
                value={value}
                onChange={handleReply}
                style={{ height: "250px" }}
              />
            </div>
              </div>
            </div>
           
            {/* <Form.Group className="mt-4">
              <Form.Control
                as="textarea"
                id="message"
                name="body"
                rows={12}
                className={`form-control ${
                  errors["message"] ? "error-field" : ""
                }`}
                placeholder="Click here to reply"
                {...register("message", {
                  required: {
                    value: true,
                    message: t("this_field_is_required"),
                  },
                })}
              />
            </Form.Group> */}

            <Form.Group className="mt-4" style={{ padding: "20px" }}>
              <div style={{ padding: "10px", display: "flex", gap: "10px" }}>
                <Button
                  variant="success"
                  type="submit"
                  onClick={handleMailSend}
                >
                  Send
                </Button>

                <Button
                  variant="danger"
                  type="submit"
                  onClick={handleMailDiscard}
                >
                  Discard
                </Button>
              </div>
            </Form.Group>
          </main>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default MailCompose;

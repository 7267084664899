import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

const MailListContainerContent = ({
  inboxes,
  setSingleMailId,
  setMailCheck,
  type,
}) => {
  const { t } = useTranslation();
  console.log(inboxes, "inboxes");
  const filterHTML = (input) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = input;
    return tempDiv.textContent || tempDiv.innerText || "";
  };
  const handleSingleMail = (id) => {
    console.log(id);
    setSingleMailId(id);
    setMailCheck(true);
  };

  return (
    <>
      <div className="border-bottom pb-4 mb-3 px-3">
        <div className="form-group">
          <input
            className="form-control w-100"
            type="search"
            placeholder="Search mail"
            id="Mail-rearch"
          />
        </div>
      </div>
      {type !== "send" && (
        <div className="selectall_checkbox">
          <label
            htmlFor="mailcheckbox"
            className="form-check-label"
            style={{ marginRight: "5px" }}
          >
            <input
              id="mailcheckbox"
              type="checkbox"
              className="form-check-input"
            />
            <i className="input-helper"></i>
          </label>
          {t("inbox")}
        </div>
      )}
      {inboxes?.length == 0 && (
        <div className="nodata-table-view">
          <div className="nodata-table-view-box">
            <div className="nodata-table-view-box-img">
              <img src="/images/no-data-image1.jpg" alt="" />
            </div>
            <div className="nodata-table-view-box-txt">
              {t("sorry_no_data_found")}
            </div>
          </div>
        </div>
      )}
      <div className="mail-list-container-scrl">
        {inboxes?.map((item, index) => (
          <div
            className="mail-list"
            key={index}
            onClick={() => handleSingleMail(item.id)}
          >
            <div className="form-check">
              <label htmlFor={`checkbox-${index}`} className="form-check-label">
                <input
                  id={`checkbox-${index}`}
                  type="checkbox"
                  className="form-check-input"
                />
                <i className="input-helper"></i>
              </label>
            </div>
            <div className="content">
              <p className="sender-name">{item.name}</p>
              <p className="sender-name">
                {" "}
                {moment(item.createdAt).format("ddd, MMM D, YYYY [at] h:mm A")}
              </p>
              <p
                dangerouslySetInnerHTML={{ __html: filterHTML(item.message) }}
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default MailListContainerContent;

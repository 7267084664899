import { MailServices } from "../../services/mailbox/mailbox";


export const Inboxes = async (data) => {
    try {
        const response = await MailServices.getInboxes(data.page,data.limit);
        return response
    } catch (error) {
        console.log(error.message);
    }
}
export const Send = async (data) => {
    try {
        const response = await MailServices.getSend(data.page,data.limit);
        return response
    } catch (error) {
        console.log(error.message);
    }
}
export const Admin = async (data) => {
    try {
        const response = await MailServices.getAdmin(data.page,data.limit);
        return response
    } catch (error) {
        console.log(error.message);
    }
}

export const SingleMail = async (id) => {
    try {
        const response = await MailServices.getSingleMail(id);
        return response
    } catch (error) {
        console.log(error.message);
    }
}

export const ReplyMail = async (data) => {
    try {
        const response = await MailServices.replyMail(data);
        return response
    } catch (error) {
        console.log(error.message);
    }
}
export const DeleteMail = async (data) => {
    try {
        console.log(data)
        const response = await MailServices.deleteMail(data);
        return response
    } catch (error) {
        console.log(error.message);
    }
}

export const mailContent = async () => {
    try {
        const response = await MailServices.mailData();
        return response
    } catch (error) {
        console.log(error.message);
    }
}
export const mailSend = async (data) => {
    try {
        const response = await MailServices.mailsend(data);
        return response
    } catch (error) {
        console.log(error.message);
    }
}
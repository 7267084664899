import React from "react";
import { useTranslation } from "react-i18next";
import MailboxComponent from "../../components/mailbox/MailboxComponent";

const MailBox = () => {
    const { t } = useTranslation();
  return (
    <>
      <div className="page_head_top">{t("mailBox")}</div>
      <MailboxComponent/>
    </>
  );
};

export default MailBox;
